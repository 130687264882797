export const CUSTOMERS_API = "/customers";
export const DEALER_INFO_API = "/dealer-info";
export const SERVICE_HISTORY_API = "/service-history";
export const CEM_API = "/cem";
export const ORDERED_VEHICLES_API = "/ordered-vehicles";
export const CUSTOMER_REVENUE_API = "/customer-revenue";
export const DEALER_REVENUE_API = "/dealer-revenue";
export const PRIVACY_POLICY_API = "/privacy-policy";
export const CAMPAIGN_INFO_API = "/campaign-info";
export const DEALER_WHITELIST_API = "/dealers/dealer-whitelist";
export const PARTS_ORDER_REVENUES_API = "/parts-order-revenues";
export const CUSTOMER_VEHICLE_API = "/customer-vehicle";
export const POTENTIALS_API = "/potentials";
export const VEHICLES_WITHOUT_EVENTS_API = "/vehicles-without-events";
export const QUALITY_REPORT_API = "/quality-report";