import React, { Component } from 'react';
import Button from 'devextreme-react/button';
import DataGrid, {
    Column,
    ColumnFixing,
    Editing,
    FilterRow, HeaderFilter,
    Lookup,
    Paging,
    RemoteOperations,
    RequiredRule,
    Scrolling,
    Sorting
} from "devextreme-react/data-grid";
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import config from "../../common/config/config";
import { CustomRule, PatternRule } from "devextreme-react/validator";

import { startSync } from '../../common/syncUtils';


const dataSource = AspNetData.createStore({
    key: 'potentialId',
    loadUrl: `${config.apiUrl}/potentials`,
    insertUrl: `${config.apiUrl}/potentials`,
    updateUrl: `${config.apiUrl}/potentials`,
    deleteUrl: `${config.apiUrl}/potentials`
});

const customersDataSource = {
    store: AspNetData.createStore({
        loadUrl: `${config.apiUrl}/customers/ids`,
    }),
    paginate: true,
    pageSize: config.pageSize
};

const dealersDataSource = {
    store: AspNetData.createStore({
        loadUrl: `${config.apiUrl}/dealers`,
    }),
    paginate: true,
    pageSize: config.pageSize
};

const potentialCategoryDataSource = ['sales', 'service', 'general'];

const potentialTypeDataSource = ['afterSalesPotential', 'guaranteePotential'];

function potentialPrivacyPolicyStatusDataSource() {
    const potentialPrivacyPolicyStatus = ['Unknown', 'Denied', 'One', 'All'];
    return potentialPrivacyPolicyStatus;
}

function getPrivacyValues(item) {
    let returnValue;
    if (item == 'Unknown')
        returnValue = null;
    else if (item == 'Denied')
        returnValue = 0;
    else if (item == 'One')
        returnValue = 1;
    else if (item == 'All')
        returnValue = 2;
    else
        returnValue = null;

    return returnValue;
}

const endpoint = 'job/start/transactional/potentials-import';
class PotentialsComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentPage: 0,
            pageLimit: 10,
            totalRecords: 0,
            pageCount: 0,
            pageNeighbours: 2,
            showFilterRow: true,
            showHeaderFilter: true,
            currentFilter: 'auto',
            selectTextOnEditStart: true,
            startEditAction: 'click',
            errors: [],
            isAuthenticated: false,
            user: undefined,
            readOnly: true,
            height: window.innerHeight - config.pxToSubtract
        };
    }


    handleSync = () => {
        try {
          startSync(endpoint);
        } catch (error) {
          console.error(error);

        }
      };



    render() {
        return (
            <div>
                <h1>Potentials</h1>
                <div>
                    <ul style={{ listStyleType: "none" }}>
                        {this.state.errors.map((value, index) => {
                            return <li style={{ color: "red" }} key={index}>{value}</li>
                        })}
                    </ul>
                </div>
                <div className="datagrid">
                    <Button text="Sync" onClick={this.handleSync} />

                    <DataGrid id="gridContainer"
                        dataSource={dataSource}
                        showBorders={true}
                        onEditorPreparing={this.onEditorPreparing}
                        rowAlternationEnabled={true}
                        showRowLines={true}
                        allowColumnResizing={true}
                        columnResizingMode={'nextColumn'}
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        height={this.state.height}
                    >
                        <ColumnFixing enabled="true" />
                        <Sorting mode="multiple" />
                        <RemoteOperations paging={true} filtering={true} sorting={true} grouping={true} />
                        <Paging defaultPageSize={config.defaultPageSize} />
                        <Scrolling mode="virtual" rowRenderingMode="virtual" />
                        <Editing
                            mode="batch"
                            allowUpdating={!this.state.readOnly}
                            allowAdding={!this.state.readOnly}
                            allowDeleting={!this.state.readOnly}
                        />
                        <FilterRow visible={this.state.showFilterRow}
                            applyFilter={this.state.currentFilter}
                            showAllText="" />
                        <HeaderFilter visible={this.state.showHeaderFilter} />
                        <Column dataField="potentialId" />
                        <Column dataField="groupNo" />
                        <Column dataField="dealerNo">
                            <RequiredRule message="Dealer number is required" />
                            <Lookup
                                dataSource={dealersDataSource}
                            />
                        </Column>
                        <Column dataField="customerId">
                            <RequiredRule message="Customer id is required" />
                            <Lookup
                                dataSource={customersDataSource}
                            />
                        </Column>
                        <Column dataField="vin">
                            <RequiredRule message="Vin is required" />
                        </Column>
                        <Column dataField="vehicleRegistrationNo">
                        </Column>
                        <Column dataField="modelText" />
                        <Column dataField="potentialBegin" dataType="date" format="yyyy-MM-dd"
                            allowHeaderFiltering={false}>
                        </Column>
                        <Column dataField="potentialEnd" dataType="date" format="yyyy-MM-dd"
                            allowHeaderFiltering={false}>
                        </Column>
                        <Column dataField="potentialCategory">
                            <RequiredRule message="Potential Category is required" />
                            <Lookup
                                dataSource={potentialCategoryDataSource}
                            />
                        </Column>
                        <Column dataField="potentialShortname">
                            <RequiredRule message="Potential Shortname is required" />
                        </Column>
                        <Column dataField="potentialType">
                            <RequiredRule message="Potential Type is required" />
                            <Lookup
                                dataSource={potentialTypeDataSource}
                            />
                        </Column>
                        <Column dataField="potentialStatus">
                            <CustomRule reevaluate={true} validationCallback={this.integerValidator} message="Potential status must be an integer value or null" />
                        </Column>
                        <Column dataField="deStatus" caption="DE-Status">
                            <Lookup
                                dataSource={potentialPrivacyPolicyStatusDataSource}
                                valueExpr={getPrivacyValues}
                            >
                            </Lookup>
                        </Column>
                        <Column dataField="brandContext">
                            <RequiredRule message="Brand Context is required" />
                        </Column>
                        <Column dataField="channelPostAllowed" dataType="boolean" />
                        <Column dataField="channelPostDate" dataType="date" format="yyyy-MM-dd" />
                        <Column dataField="channelPhoneAllowed" dataType="boolean" />
                        <Column dataField="channelPhoneDate" dataType="date" format="yyyy-MM-dd" />
                        <Column dataField="channelEmailAllowed" dataType="boolean" />
                        <Column dataField="channelEmailDate" dataType="date" format="yyyy-MM-dd" />
                        <Column dataField="generalInspectionDate">
                            <PatternRule
                                message={'General Inspection Date must have "yyyy-MM" format!'}
                                pattern={/^\d{4}-\d{2}$/i}
                            />
                        </Column>
                        <Column dataField="lastEventDealerNo" />

                        <Column dataField="sellerPerson" dataType="string" />
                        <Column dataField="firstServicePersonName" dataType="string" />
                        <Column dataField="dataSet" dataType="json" />

                        <Column dataField="isVehicleInOrder" dataType="boolean" />
                        <Column dataField="lastServiceDate" dataType="date" format="yyyy-MM-dd"
                                                    allowHeaderFiltering={false}>
                            <RequiredRule message="Last service date is required" />
                        </Column>
                        <Column dataField="isOwner" dataType="boolean">
                            <CustomRule reevaluate={true} validationCallback={this.validationCallback} message="Is Owner field is required" />
                        </Column>

                        <Column dataField="isDriver" dataType="boolean">
                            <CustomRule reevaluate={true} validationCallback={this.validationCallback} message="Is Driver field is required" />
                        </Column>

                        <Column dataField="isInvoiceRecipient" dataType="boolean">
                            <CustomRule reevaluate={true} validationCallback={this.validationCallback} message="Is Invoice Recipient field is required" />
                        </Column>
                    </DataGrid>
                </div>
            </div>
        );
    };

    validationCallback = (data) => {
        return data.value !== undefined && data.value !== null;
    };

    integerValidator = (data) => {
        if (data.value === null || data.value === undefined) {
            return true;
        }
        return data.value >= config.integerMinValue && data.value <= config.integerMaxValue;
    };

    onEditorPreparing = (event) => {
        if (event.dataField !== "potentialId" && event.dataField !== "groupNo") {
            return;
        }
        if (event.row !== undefined) {
            event.editorOptions.disabled = true;
        }
    };

    componentDidMount() {
        fetch(`${config.authUrl}`)
            .then(response => {
                response.json()
                    .then(body => {
                        if (body['authenticated'] === false) {
                            this.setState({ isAuthenticated: false });
                            window.location.reload();
                        } else {
                            this.setState({
                                isAuthenticated: true,
                                readOnly: body.readOnly
                            });
                        }
                    });
            });
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        this.setState({ height: parseInt(window.innerHeight) - config.pxToSubtract });
    }
}

export default PotentialsComponent;