import React, {Component} from "react";
import {Link} from "react-router-dom";
import './Header.css';
import config from "../../common/config/config";
import * as constants from "../../common/constants";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            user: undefined,
            beta: false,
            forbiddenEndpoints: []
        };
    }

    render() {
        return (
            <header>
                <nav className="navbar navbar-expand-md navbar-custom">
                    <div><Link className="navbar-brand" to={"/"}>Self Service Portal</Link></div>
                    {this.state.isAuthenticated === true && !this.state.forbiddenEndpoints.includes(constants.CUSTOMERS_API) &&
                    <ul className="navbar-nav">
                        <li><Link className="nav-link" to={constants.CUSTOMERS_API}>Customers</Link></li>
                    </ul>
                    }
                    {this.state.isAuthenticated === true && !this.state.forbiddenEndpoints.includes(constants.DEALER_INFO_API) &&
                    <ul className="navbar-nav">
                        <li><Link className="nav-link" to={constants.DEALER_INFO_API}>Dealer Info</Link></li>
                    </ul>
                    }
                    {this.state.isAuthenticated === true && !this.state.forbiddenEndpoints.includes(constants.SERVICE_HISTORY_API) &&
                    <ul className="navbar-nav">
                        <li><Link className="nav-link" to={constants.SERVICE_HISTORY_API}>Service History</Link></li>
                    </ul>
                    }

                    {this.state.isAuthenticated === true && !this.state.forbiddenEndpoints.includes(constants.CEM_API) &&
                    <ul className="navbar-nav">
                        <li><Link className="nav-link" to={constants.CEM_API}>CEM</Link></li>
                    </ul>
                    }
                    {this.state.isAuthenticated === true && !this.state.forbiddenEndpoints.includes(constants.ORDERED_VEHICLES_API) &&
                    <ul className="navbar-nav">
                        <li><Link className="nav-link" to={constants.ORDERED_VEHICLES_API}>Ordered Vehicles</Link></li>
                    </ul>
                    }
                    {this.state.isAuthenticated === true && !this.state.forbiddenEndpoints.includes(constants.CUSTOMER_REVENUE_API) &&
                    <ul className="navbar-nav">
                        <li><Link className="nav-link" to={constants.CUSTOMER_REVENUE_API}>Customer Revenue</Link></li>
                    </ul>
                    }
                    {this.state.isAuthenticated === true && !this.state.forbiddenEndpoints.includes(constants.DEALER_REVENUE_API) &&
                    <ul className="navbar-nav">
                        <li><Link className="nav-link" to={constants.DEALER_REVENUE_API}>Dealer Revenue</Link></li>
                    </ul>
                    }
                    {this.state.isAuthenticated === true && !this.state.forbiddenEndpoints.includes(constants.PRIVACY_POLICY_API) &&
                    <ul className="navbar-nav">
                        <li><Link className="nav-link" to={constants.PRIVACY_POLICY_API}>Privacy Policy</Link></li>
                    </ul>
                    }
                    {this.state.isAuthenticated === true && !this.state.forbiddenEndpoints.includes(constants.CAMPAIGN_INFO_API) &&
                    <ul className="navbar-nav">
                        <li><Link className="nav-link" to={constants.CAMPAIGN_INFO_API}>Campaign Info</Link></li>
                    </ul>
                    }
                    {this.state.isAuthenticated === true && !this.state.forbiddenEndpoints.includes(constants.DEALER_WHITELIST_API) &&
                    <ul className="navbar-nav">
                        <li><Link className="nav-link" to={constants.DEALER_WHITELIST_API}>Dealer Whitelist</Link></li>
                    </ul>
                    }
                    {this.state.isAuthenticated === true && !this.state.forbiddenEndpoints.includes(constants.PARTS_ORDER_REVENUES_API) &&
                    <ul className="navbar-nav">
                        <li><Link className="nav-link" to={constants.PARTS_ORDER_REVENUES_API}>Parts Order Revenues</Link></li>
                    </ul>
                    }
                    {this.state.isAuthenticated === true && !this.state.forbiddenEndpoints.includes(constants.CUSTOMER_VEHICLE_API) &&
                    <ul className="navbar-nav">
                        <li><Link className="nav-link" to={constants.CUSTOMER_VEHICLE_API}>Customer Vehicle</Link></li>
                    </ul>
                    }
                    {this.state.isAuthenticated === true && !this.state.forbiddenEndpoints.includes(constants.POTENTIALS_API) &&
                    <ul className="navbar-nav">
                        <li><Link className="nav-link" to={constants.POTENTIALS_API}>Potentials</Link></li>
                    </ul>
                    }
                    {this.state.isAuthenticated === true && !this.state.forbiddenEndpoints.includes(constants.VEHICLES_WITHOUT_EVENTS_API) &&
                    <ul className="navbar-nav">
                        <li><Link className="nav-link" to={constants.VEHICLES_WITHOUT_EVENTS_API}>Vehicles without Events</Link></li>
                    </ul>
                    }
                    {this.state.isAuthenticated === true && !this.state.forbiddenEndpoints.includes(constants.QUALITY_REPORT_API) &&
                    <ul className="navbar-nav">
                        <li><Link className="nav-link" to={constants.QUALITY_REPORT_API}>Quality Report</Link></li>
                    </ul>
                    }

                    <ul className="navbar-nav navbar-collapse justify-content-end">
                        {!this.state.isAuthenticated ?
                            <li><Link className="nav-link" onClick={this.login}>Login</Link></li> : null}
                        {this.state.isAuthenticated ?
                            <li><Link className="nav-link" onClick={this.logout}>Logout</Link></li> : null}
                    </ul>
                </nav>
            </header>
        )
    }

    componentDidMount() {
        fetch(`${config.authUrl}`)
            .then(response => {
                response.json()
                    .then(body => {
                        if (body['authenticated'] === false) {
                            this.setState({isAuthenticated: false});
                        } else {
                            this.setState({
                                isAuthenticated: true,
                                beta: body.beta
                            });
                        }
                    });
            });

        fetch("/forbidden-endpoints")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        forbiddenEndpoints: result
                    });
                })
            .then(res => {
                if (this.state.forbiddenEndpoints.length > 0) {
                    let endpoints = this.state.forbiddenEndpoints;

                    endpoints = endpoints.map(endpoint => {
                        return endpoint.replace("/api", "");
                    });
                    this.setState({
                        forbiddenEndpoints: endpoints
                    });
                }
            });
    }

    login = () => {
        window.location.href = `${config.oauth2Login}`;
    };

    logout = () => {
        window.location.href = `${config.oauth2Logout}`;
    }
}

export default Header;