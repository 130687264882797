import React, { Component } from 'react';
import Button from 'devextreme-react/button';
import DataGrid, {
    Column, ColumnFixing,
    Editing,
    FilterRow,
    HeaderFilter,
    Lookup,
    MasterDetail,
    Paging,
    RemoteOperations,
    RequiredRule,
    Scrolling, Sorting
} from "devextreme-react/data-grid";
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from "devextreme/data/data_source";
import { Item, TabPanel } from "devextreme-react/tab-panel";
import config from "../../common/config/config";

import { startSync } from "../../common/syncUtils";


const dataSource = AspNetData.createStore({
    key: 'identityId',
    loadUrl: `${config.apiUrl}/campaign-info`,
    insertUrl: `${config.apiUrl}/campaign-info`,
    updateUrl: `${config.apiUrl}/campaign-info`,
    deleteUrl: `${config.apiUrl}/campaign-info`
});

const dealersDataSource = {
    store: AspNetData.createStore({
        loadUrl: `${config.apiUrl}/dealers`,
    }),
    paginate: true,
    pageSize: config.pageSize
};

const customersDataSource = {
    store: AspNetData.createStore({
        loadUrl: `${config.apiUrl}/customers/ids`,
    }),
    paginate: true,
    pageSize: config.pageSize
};

const statusCodeDataSource = [1, 2, 3];

const endpoint = 'job/start/transactional/campaign-info-import'

class CampaignInfoComponent extends Component {
    handleSync = () => {
        try {
          startSync(endpoint);
        } catch (error) {
          console.error(error);
          
        }
      };

    constructor(props) {
        super(props);


        this.state = {
            currentPage: 0,
            pageLimit: 10,
            totalRecords: 0,
            pageCount: 0,
            pageNeighbours: 2,
            showFilterRow: true,
            showHeaderFilter: true,
            currentFilter: 'auto',
            selectTextOnEditStart: true,
            startEditAction: 'click',
            errors: [],
            isAuthenticated: false,
            user: undefined,
            mode: 'nextColumn',
            readOnly: true,
            height: window.innerHeight - config.pxToSubtract
        };
    }






    render() {
        return (
            <div>
                <h1>Campaign Info</h1>
                <div>
                    <ul style={{ listStyleType: "none" }}>
                        {this.state.errors.map((value, index) => {
                            return <li style={{ color: "red" }} key={index}>{value}</li>
                        })}
                    </ul>
                </div>
                <div className="datagrid">
                    <Button text="Sync" onClick={this.handleSync} />

                    <DataGrid id="gridContainer"
                        dataSource={dataSource}
                        showBorders={true}
                        onEditorPreparing={this.onEditorPreparing}
                        keyExpr="id"
                        rowAlternationEnabled={true}
                        showRowLines={true}
                        allowColumnResizing={true}
                        columnResizingMode={'nextColumn'}
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        height={this.state.height}
                    >
                        <ColumnFixing enabled="true" />
                        <Sorting mode="multiple" />
                        <RemoteOperations paging={true} filtering={true} sorting={true} grouping={true} />
                        <Paging defaultPageSize={config.defaultPageSize} />
                        <Scrolling mode="virtual" rowRenderingMode="virtual" />
                        <Editing
                            mode="batch"
                            allowUpdating={!this.state.readOnly}
                            allowAdding={!this.state.readOnly}
                            allowDeleting={!this.state.readOnly}
                        />
                        <FilterRow visible={this.state.showFilterRow}
                            applyFilter={this.state.currentFilter}
                            showAllText="" />
                        <HeaderFilter visible={this.state.showHeaderFilter} />
                        <Column dataField="groupNo" />
                        <Column dataField="dealerNo">
                            <RequiredRule message="Dealer number is required" />
                            <Lookup
                                dataSource={dealersDataSource}
                            />
                        </Column>
                        <Column dataField="customerId">
                            <RequiredRule message="Customer id is required" />
                            <Lookup
                                dataSource={customersDataSource}
                            />
                        </Column>
                        <Column dataField="brand">
                            <RequiredRule message="Brand is required" />
                        </Column>
                        <Column dataField="startDate" dataType="date" format="yyyy-MM-dd" allowHeaderFiltering={false}>
                            <RequiredRule message="Start Date is required" />
                        </Column>
                        <Column dataField="endDate" dataType="date" format="yyyy-MM-dd" allowHeaderFiltering={false}>
                            <RequiredRule message="End Date is required" />
                        </Column>
                        <Column dataField="sendDate" dataType="date" format="yyyy-MM-dd" allowHeaderFiltering={false} />
                        <Column dataField="statusCode" setCellValue={this.setSellValue}>
                            <RequiredRule message="StatusCode is required" />
                            <Lookup
                                dataSource={statusCodeDataSource}
                            />
                        </Column>
                        <Column dataField="status">
                            <RequiredRule message="Status is required" />
                        </Column>

                        <Column dataField="title">
                            <RequiredRule message="Title is required" />
                        </Column>
                        <MasterDetail
                            enabled={true}
                            component={DetailTemplate}
                        />
                    </DataGrid>
                </div>
            </div>
        );
    };

    setSellValue = (newData, value, currentRowData) => {
        newData.statusCode = value;
        if (value === 1) {
            newData.status = 'Geplant';
        } else if (value === 2) {
            newData.status = 'Laufend';
        } else {
            newData.status = 'Beendet';
        }
    };

    onEditorPreparing = (event) => {
        if (event.dataField !== "groupNo") {
            return;
        }
        if (event.row !== undefined) {
            event.editorOptions.disabled = true;
        }
    };

    componentDidMount() {
        fetch(`${config.authUrl}`)
            .then(response => {
                response.json()
                    .then(body => {
                        if (body['authenticated'] === false) {
                            this.setState({ isAuthenticated: false });
                            window.location.reload();
                        } else {
                            this.setState({
                                isAuthenticated: true,
                                readOnly: body.readOnly
                            });
                        }
                    });
            });
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        this.setState({ height: parseInt(window.innerHeight) - config.pxToSubtract });
    }
}

class DetailTemplate extends Component {

    render() {
        return (
            <TabPanel>
                <Item title="Vehicles" render={this.renderVehiclesTab} />
            </TabPanel>
        );
    }

    renderVehiclesTab = () => {
        return <VehiclesTab campaignInfoId={this.props.data.key} />;
    }
}

class VehiclesTab extends Component {

    constructor(props) {


        super(props);
        this.vehicleCampaignInfoId = props.campaignInfoId;
        this.state = {
            campaignInfoId: props.campaignInfoId,
            readOnly: true
        };
        this.vehiclesDataSource = new DataSource({
            store: AspNetData.createStore({
                key: ['brand', 'modelText', 'vin', 'vehicleRegistrationNo', 'campaignInfoId'],
                loadUrl: `${config.apiUrl}/campaign-info/vehicles?id=` + this.vehicleCampaignInfoId,
                insertUrl: `${config.apiUrl}/campaign-info/vehicles?id=` + this.vehicleCampaignInfoId,
                updateUrl: `${config.apiUrl}/campaign-info/vehicles?id=` + this.vehicleCampaignInfoId,
                deleteUrl: `${config.apiUrl}/campaign-info/vehicles?id=` + this.vehicleCampaignInfoId
            })
        });
    }

    render() {
        return (
            <React.Fragment>
                <DataGrid
                    dataSource={this.vehiclesDataSource}
                    showBorders={true}
                    columnAutoWidth={true}
                    keyExpr="dealerNo"
                    rowAlternationEnabled={true}
                    showRowLines={true}
                    allowColumnResizing={true}
                    columnResizingMode={'nextColumn'}
                    allowColumnReordering={true}
                >
                    <Editing
                        mode="batch"
                        allowUpdating={!this.state.readOnly}
                        allowAdding={!this.state.readOnly}
                        allowDeleting={!this.state.readOnly}
                    />
                    <Column dataField="brand">
                        <RequiredRule message="Brand is required" />
                    </Column>
                    <Column dataField="modelText" />
                    <Column dataField="vin" />
                    <Column dataField="vehicleRegistrationNo" />
                </DataGrid>
            </React.Fragment>
        );
    }

    componentDidMount() {
        fetch(`${config.authUrl}`)
            .then(response => {
                response.json()
                    .then(body => {
                        if (body['authenticated'] === false) {
                            this.setState({ isAuthenticated: false });
                            window.location.reload();
                        } else {
                            this.setState({
                                isAuthenticated: true,
                                readOnly: body.readOnly
                            });
                        }
                    });
            });
    }
}


export default CampaignInfoComponent;