import React, { Component } from 'react';
import Button from 'devextreme-react/button';
import DataGrid, {
    Column,
    ColumnFixing,
    Editing,
    FilterRow, HeaderFilter,
    Lookup,
    Paging,
    RemoteOperations,
    RequiredRule,
    Scrolling,
    Sorting,
    Export
} from "devextreme-react/data-grid";
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import config from "../../common/config/config";


const dataSource = AspNetData.createStore({
    key : ['dealerNo','reportedAt'],
    loadUrl: `${config.apiUrl}/quality-report`
});

class QualityReportComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentPage: 0,
            pageLimit: 10,
            totalRecords: 0,
            pageCount: 0,
            pageNeighbours: 2,
            showFilterRow: true,
            showHeaderFilter: true,
            currentFilter: 'auto',
            selectTextOnEditStart: true,
            startEditAction: 'click',
            errors: [],
            isAuthenticated: false,
            user: undefined,
            readOnly: true,
            height: window.innerHeight - config.pxToSubtract
        };
    }

    render() {
         return (
                <div>
                    <h1>Quality Report</h1>
                    <div>
                        <ul style={{ listStyleType: "none" }}>
                            {this.state.errors.map((value, index) => {
                                return <li style={{ color: "red" }} key={index}>{value}</li>
                            })}
                        </ul>
                    </div>
                    <div className="datagrid">

                        <DataGrid id="gridContainer"
                            dataSource={dataSource}
                            showBorders={true}
                            onEditorPreparing={this.onEditorPreparing}
                            keyExpr="id"
                            rowAlternationEnabled={true}
                            showRowLines={true}
                            allowColumnResizing={true}
                            columnResizingMode={'nextColumn'}
                            allowColumnReordering={true}
                            columnAutoWidth={true}
                            height={this.state.height}
                        >
                            <ColumnFixing enabled="true" />
                            <Sorting mode="multiple" />
                            <RemoteOperations paging={true} filtering={true} sorting={true} grouping={true} />
                            <Paging defaultPageSize={config.defaultPageSize} />
                            <Scrolling mode="virtual" rowRenderingMode="virtual" />

                            <FilterRow visible={this.state.showFilterRow}
                                applyFilter={this.state.currentFilter}
                                showAllText="" />
                            <HeaderFilter visible={this.state.showHeaderFilter} />

                            <Column dataField="dealerNo"/>

                            <Column dataField="reportedAt" dataType="date" format="yyyy-MM-dd"
                                allowHeaderFiltering={false}/>

                            <Column dataField="groupNo" />

                            <Column dataField="dmsName" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="dmsVersion" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="customersTotal" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="dsmariaVersion" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="customersEmptyNo" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="vehiclesEmptyVin" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="customersEmptyName" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="orderedVehiclesTotal" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="customersDuplicatedNo" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="vehiclesEmptyModelKey" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="vehiclesEmptyModelText" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="customersDuplicatedEmail" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="potentialCurrentStatistics" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="newadaEventsCountLastYear" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="dsmariaEventsCountLastYear" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="orderedVehiclesNotDelivered" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="newadaEventsEmptyCustomerNo" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="vehiclesEmptyRegistrationDate" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="newadaBsdEventsEmptyCustomerNo" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="orderedVehiclesDuplicateDelivery" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="orderedVehiclesEmptyCommissionNo" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="serviceHistoryOrderPeriodTooLong" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="orderedVehiclesNotDeliveredTooOld" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="vehiclesEmptyGeneralInspectionDate" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="newadaUniqueCustomersCountLastYear" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="orderedVehiclesDeliveredWithoutDate" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="dsmariaUniqueCustomersCountLastYear" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Column dataField="orderedVehiclesDummyRegistrationDate" allowHeaderFiltering={false} allowFiltering={false}/>
                            <Export enabled={true} fileName="Quality-Report" exportAll={true} />

                        </DataGrid>
                    </div>
                </div>
            );
        };

    componentDidMount() {
        fetch(`${config.authUrl}`)
            .then(response => {
                response.json()
                    .then(body => {
                        if (body['authenticated'] === false) {
                            this.setState({ isAuthenticated: false });
                            window.location.reload();
                        } else {
                            this.setState({
                                isAuthenticated: true,
                                readOnly: body.readOnly
                            });
                        }
                    });
            });
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        this.setState({ height: parseInt(window.innerHeight) - config.pxToSubtract });
    }
}


export default QualityReportComponent;
