import React, { Component } from 'react';
import Header from "./header/Header";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CustomerComponent from "./customer/CustomerComponent";
import DealerInfoComponent from "./dealer/dealer_info/DealerInfoComponent";
import ServiceHistoryComponent from "./service_history/ServiceHistoryComponent";
import CemBackflowComponent from "./cem_backflow/CemBackflowComponent";
import OrderedVehiclesComponent from "./ordered_vehicles/OrderedVehiclesComponent";
import CustomerRevenueComponent from "./revenue/customer_revenue/CustomerRevenueComponent";
import DealerRevenueComponent from "./revenue/dealer_revenue/DealerRevenueComponent";
import PrivacyPolicyComponent from "./privacy_policy/PrivacyPolicyComponent";
import CampaignInfoComponent from "./campaign_info/CampaignInfoComponent";
import * as constants from "../common/constants"
import DealerWhitelistComponent from "./dealer/dealer_whitelist/DealerWhitelistComponent";
import PartsOrderRevenuesComponent from "./parts_order_revenue/PartsOrderRevenuesComponent";
import CustomerVehicleComponent from "./customer_vehicle/CustomerVehicleComponent";
import PotentialsComponent from "./potentials/PotentialsComponent";
import VehiclesWithoutEventsComponent from "./vehicles_without_events/VehiclesWithoutEventsComponent";
import QualityReportComponent from "./quality_report/QualityReportComponent";

class MainComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            forbiddenEndpoints: []
        }
    }

    componentDidMount() {
        fetch("/forbidden-endpoints")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        forbiddenEndpoints: result
                    });
                })
            .then(res => {
                if (this.state.forbiddenEndpoints.length > 0) {
                    let endpoints = this.state.forbiddenEndpoints;

                    endpoints = endpoints.map(endpoint => {
                        return endpoint.replace("/api", "");
                    });
                    this.setState({
                        forbiddenEndpoints: endpoints
                    });
                }
            });
    }

    render() {
        return (
            <div>
                <Router>
                    <Header />
                    <Switch>
                        <Route path="/" exact component={MainPage} />
                        {!this.state.forbiddenEndpoints.includes(constants.CUSTOMERS_API) &&
                            <Route path={constants.CUSTOMERS_API} component={CustomerComponent} />
                        }
                        {!this.state.forbiddenEndpoints.includes(constants.DEALER_INFO_API) &&
                            <Route path={constants.DEALER_INFO_API} component={DealerInfoComponent} />
                        }
                        {!this.state.forbiddenEndpoints.includes(constants.SERVICE_HISTORY_API) &&
                            <Route path={constants.SERVICE_HISTORY_API} component={ServiceHistoryComponent} />
                        }
                        {!this.state.forbiddenEndpoints.includes(constants.CEM_API) &&
                            <Route path={constants.CEM_API} component={CemBackflowComponent} />
                        }
                        {!this.state.forbiddenEndpoints.includes(constants.ORDERED_VEHICLES_API) &&
                            <Route path={constants.ORDERED_VEHICLES_API} component={OrderedVehiclesComponent} />
                        }
                        {!this.state.forbiddenEndpoints.includes(constants.CUSTOMER_REVENUE_API) &&
                            <Route path={constants.CUSTOMER_REVENUE_API} component={CustomerRevenueComponent} />
                        }
                        {!this.state.forbiddenEndpoints.includes(constants.DEALER_REVENUE_API) &&
                            <Route path={constants.DEALER_REVENUE_API} component={DealerRevenueComponent} />
                        }
                        {!this.state.forbiddenEndpoints.includes(constants.PRIVACY_POLICY_API) &&
                            <Route path={constants.PRIVACY_POLICY_API} component={PrivacyPolicyComponent} />
                        }
                        {!this.state.forbiddenEndpoints.includes(constants.CAMPAIGN_INFO_API) &&
                            <Route path={constants.CAMPAIGN_INFO_API} component={CampaignInfoComponent} />
                        }
                        {!this.state.forbiddenEndpoints.includes(constants.DEALER_WHITELIST_API) &&
                            <Route path={constants.DEALER_WHITELIST_API} component={DealerWhitelistComponent} />
                        }
                        {!this.state.forbiddenEndpoints.includes(constants.PARTS_ORDER_REVENUES_API) &&
                            <Route path={constants.PARTS_ORDER_REVENUES_API} component={PartsOrderRevenuesComponent} />
                        }
                        {!this.state.forbiddenEndpoints.includes(constants.CUSTOMER_VEHICLE_API) &&
                            <Route path={constants.CUSTOMER_VEHICLE_API} component={CustomerVehicleComponent} />
                        }
                        {!this.state.forbiddenEndpoints.includes(constants.POTENTIALS_API) &&
                            <Route path={constants.POTENTIALS_API} component={PotentialsComponent} />
                        }
                        {!this.state.forbiddenEndpoints.includes(constants.VEHICLES_WITHOUT_EVENTS_API) &&
                            <Route path={constants.VEHICLES_WITHOUT_EVENTS_API} component={VehiclesWithoutEventsComponent} />
                        }
                        {!this.state.forbiddenEndpoints.includes(constants.QUALITY_REPORT_API) &&
                            <Route path={constants.QUALITY_REPORT_API} component={QualityReportComponent} />
                        }
                    </Switch>
                </Router>
            </div>
        )
    }
}

class MainPage extends Component {

    render() {
        return (
            <div>
                <h1>Self Service Portal</h1>
            </div>
        )
    }
}

export default MainComponent;