import logo from './logo.svg';
import React from "react";
import './App.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import MainComponent from "./components/MainComponent";

function App() {
  return (
    <div className="App">
      <MainComponent />
    </div>
  );
}

export default App;
