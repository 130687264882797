
import config from "./config/config";
export const startSync = (a) => {
  if (!a) {
    throw new Error('Sync did not find an endpoint target');
  }

  const b = process.env.REACT_APP_API_BASE_URLS.split(',');
  const c = b.map(d => d + a);
  const e = [];

  c.forEach(f => {
    const g = {
      url: f
    };
    const h = fetch(`${config.apiUrl}/rp`, {
      method: 'POST',
      body: JSON.stringify(g),
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then(i => i.text())
      .then(j => {
        console.log(j);
        const k = j.includes('started with ID ');
        return {
          url: f,
          success: k
        };
      })
      .catch(m => {
        console.error(m);
        return {
          url: f,
          success: false
        };
      });

    e.push(h);
  });

  return Promise.all(e)
    .then(n => {
      let o = '';
      n.forEach(p => {
        o += p.success ? `Sync started successfully for ${p.url}! ✅\n` : `Data sync has encountered an issue for ${p.url}! ⚠️\n`;
      });
      alert(o);
    });
};