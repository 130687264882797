import React, { Component } from 'react';
import Button from 'devextreme-react/button';
import DataGrid, {
    Column,
    ColumnFixing,
    Editing,
    FilterRow, HeaderFilter,
    Lookup,
    Paging,
    RemoteOperations,
    RequiredRule,
    Scrolling,
    Sorting
} from "devextreme-react/data-grid";
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import config from "../../common/config/config";
import { CustomRule } from "devextreme-react/validator";

import { startSync } from '../../common/syncUtils';

const dataSource = AspNetData.createStore({
    key: 'identityId',
    loadUrl: `${config.apiUrl}/vehicles-without-events`,
    insertUrl: `${config.apiUrl}/vehicles-without-events`,
    updateUrl: `${config.apiUrl}/vehicles-without-events`,
    deleteUrl: `${config.apiUrl}/vehicles-without-events`
});

const customersDataSource = {
    store: AspNetData.createStore({
        loadUrl: `${config.apiUrl}/customers/ids`,
    }),
    paginate: true,
    pageSize: config.pageSize
};

const dealersDataSource = {
    store: AspNetData.createStore({
        loadUrl: `${config.apiUrl}/dealers`,
    }),
    paginate: true,
    pageSize: config.pageSize
};

const endpoint = 'job/start/transactional/customer-vehicle-without-events-import'

class VehiclesWithoutEventsComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentPage: 0,
            pageLimit: 10,
            totalRecords: 0,
            pageCount: 0,
            pageNeighbours: 2,
            showFilterRow: true,
            showHeaderFilter: true,
            currentFilter: 'auto',
            selectTextOnEditStart: true,
            startEditAction: 'click',
            errors: [],
            isAuthenticated: false,
            user: undefined,
            readOnly: true,
            height: window.innerHeight - config.pxToSubtract
        };
    }

        

    handleSync = () => {
        try {
          startSync(endpoint);
        } catch (error) {
          console.error(error);
          
        }
      };

    render() {
        return (
            <div>
                <h1>Vehicles Without Events</h1>
                <div>
                    <ul style={{ listStyleType: "none" }}>
                        {this.state.errors.map((value, index) => {
                            return <li style={{ color: "red" }} key={index}>{value}</li>
                        })}
                    </ul>
                </div>
                <div className="datagrid">
                    <Button text="Sync" onClick={this.handleSync} />

                    <DataGrid id="gridContainer"
                        dataSource={dataSource}
                        showBorders={true}
                        onEditorPreparing={this.onEditorPreparing}
                        rowAlternationEnabled={true}
                        showRowLines={true}
                        allowColumnResizing={true}
                        columnResizingMode={'nextColumn'}
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        height={this.state.height}
                    >
                        <ColumnFixing enabled="true" />
                        <Sorting mode="multiple" />
                        <RemoteOperations paging={true} filtering={true} sorting={true} grouping={true} />
                        <Paging defaultPageSize={config.defaultPageSize} />
                        <Scrolling mode="virtual" rowRenderingMode="virtual" />
                        <Editing
                            mode="batch"
                            allowUpdating={!this.state.readOnly}
                            allowAdding={!this.state.readOnly}
                            allowDeleting={!this.state.readOnly}
                        />
                        <FilterRow visible={this.state.showFilterRow}
                            applyFilter={this.state.currentFilter}
                            showAllText="" />
                        <HeaderFilter visible={this.state.showHeaderFilter} />
                        <Column dataField="groupNo" />
                        <Column dataField="dealerNo">
                            <RequiredRule message="Dealer number is required" />
                            <Lookup
                                dataSource={dealersDataSource}
                            />
                        </Column>
                        <Column dataField="customerId">
                            <RequiredRule message="Customer id is required" />
                            <Lookup
                                dataSource={customersDataSource}
                            />
                        </Column>
                        <Column dataField="brand">
                            <RequiredRule message="Brand is required" />
                            <CustomRule reevaluate={true} validationCallback={this.integerValidator} message="Brand must be >= -2,147,483,648 and <= 2,147,483,647" />
                        </Column>
                        <Column dataField="modelKey" />
                        <Column dataField="modelText" />
                        <Column dataField="vin" />
                        <Column dataField="vehicleRegistrationNo" />
                        <Column dataField="registrationDate" dataType="date" format="yyyy-MM-dd"
                            allowHeaderFiltering={false} />
                        <Column dataField="sellerPerson" />
                        <Column dataField="isOwner" dataType="boolean" />
                        <Column dataField="mileage" />
                        <Column dataField="mileageAnnual" />

                    </DataGrid>
                </div>
            </div>
        );
    };

    validationCallback = (data) => {
        return data.value !== undefined && data.value !== null;
    };

    integerValidator = (data) => {
        return data.value >= config.integerMinValue && data.value <= config.integerMaxValue;
    };

    onEditorPreparing = (event) => {
        if (event.dataField !== "id" && event.dataField !== "groupNo") {
            return;
        }
        if (event.row !== undefined) {
            event.editorOptions.disabled = true;
        }
    };

    componentDidMount() {
        fetch(`${config.authUrl}`)
            .then(response => {
                response.json()
                    .then(body => {
                        if (body['authenticated'] === false) {
                            this.setState({ isAuthenticated: false });
                            window.location.reload();
                        } else {
                            this.setState({
                                isAuthenticated: true,
                                readOnly: body.readOnly
                            });
                        }
                    });
            });
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        this.setState({ height: parseInt(window.innerHeight) - config.pxToSubtract });
    }
}


export default VehiclesWithoutEventsComponent;