import React, { Component } from 'react';
import Button from 'devextreme-react/button';
import DataGrid, {
    Column, ColumnFixing,
    Editing,
    FilterRow,
    HeaderFilter,
    Lookup,
    MasterDetail,
    Paging,
    RemoteOperations,
    RequiredRule,
    Scrolling, Sorting
} from "devextreme-react/data-grid";
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from "devextreme/data/data_source";
import { Item, TabPanel } from "devextreme-react/tab-panel";
import config from "../../../common/config/config";

import { startSync } from '../../../common/syncUtils';

const dataSource = AspNetData.createStore({
    key: 'id',
    loadUrl: `${config.apiUrl}/dealer-info`,
    insertUrl: `${config.apiUrl}/dealer-info`,
    updateUrl: `${config.apiUrl}/dealer-info`,
    deleteUrl: `${config.apiUrl}/dealer-info`
});

const dealersDataSource = {
    store: AspNetData.createStore({
        loadUrl: `${config.apiUrl}/dealers`,
    }),
    paginate: true,
    pageSize: config.pageSize
};

const endpoint = 'job/start/transactional/dealer-info-import'

class DealerInfoComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentPage: 0,
            pageLimit: 10,
            totalRecords: 0,
            pageCount: 0,
            pageNeighbours: 2,
            showFilterRow: true,
            showHeaderFilter: true,
            currentFilter: 'auto',
            selectTextOnEditStart: true,
            startEditAction: 'click',
            errors: [],
            isAuthenticated: false,
            user: undefined,
            mode: 'nextColumn',
            readOnly: true,
            height: window.innerHeight - config.pxToSubtract
        };
    }


        


    handleSync = () => {
        try {
          startSync(endpoint);
        } catch (error) {
          console.error(error);
          
        }
      };

    render() {
        return (
            <div>
                <h1>Dealer Info</h1>
                <div>
                    <ul style={{ listStyleType: "none" }}>
                        {this.state.errors.map((value, index) => {
                            return <li style={{ color: "red" }} key={index}>{value}</li>
                        })}
                    </ul>
                </div>
                <div className="datagrid">
                    <Button text="Sync" onClick={this.handleSync} />

                    <DataGrid id="gridContainer"
                        dataSource={dataSource}
                        showBorders={true}
                        onEditorPreparing={this.onEditorPreparing}
                        keyExpr="id"
                        rowAlternationEnabled={true}
                        showRowLines={true}
                        allowColumnResizing={true}
                        columnResizingMode={'nextColumn'}
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        height={this.state.height}
                    >
                        <ColumnFixing enabled="true" />
                        <Sorting mode="multiple" />
                        <RemoteOperations paging={true} filtering={true} sorting={true} grouping={true} />
                        <Paging defaultPageSize={config.defaultPageSize} />
                        <Scrolling mode="virtual" rowRenderingMode="virtual" />
                        <Editing
                            mode="batch"
                            allowUpdating={!this.state.readOnly}
                            allowAdding={!this.state.readOnly}
                            allowDeleting={!this.state.readOnly}
                        />
                        <FilterRow visible={this.state.showFilterRow}
                            applyFilter={this.state.currentFilter}
                            showAllText="" />
                        <HeaderFilter visible={this.state.showHeaderFilter} />

                        <Column dataField="id">
                            <RequiredRule message="Id is required" />
                            <HeaderFilter allowSearch={true} />
                        </Column>
                        <Column dataField="dealerNo">
                            <RequiredRule message="Dealer number is required" />
                            <Lookup
                                dataSource={dealersDataSource}
                            />
                        </Column>
                        <Column dataField="dmsName" />
                        <Column dataField="dmsVersion" />
                        <Column dataField="dsmariaVersion" />
                        <Column dataField="dataLastEvent" dataType="date" format="yyyy-MM-dd" allowHeaderFiltering={false} />
                        <Column dataField="dataReceived" dataType="datetime" format="yyyy-MM-dd HH:mm:ss" allowHeaderFiltering={false} />
                        <Column dataField="dataProcessed" dataType="datetime" format="yyyy-MM-dd HH:mm:ss" allowHeaderFiltering={false} />
                        <Column dataField="groupNo" />
                        <Column dataField="groupName" />
                        <Column dataField="groupMemberSince" dataType="date" format="yyyy-MM-dd" allowHeaderFiltering={false} />
                        <Column dataField="groupLastChange" dataType="date" format="yyyy-MM-dd" allowHeaderFiltering={false} />
                        <Column dataField="dskureVersion" />

                        <MasterDetail
                            enabled={true}
                            component={DetailTemplate}
                        />
                    </DataGrid>
                </div>
            </div>
        );
    };

    onEditorPreparing = (event) => {
        if (event.dataField !== "id") {
            return;
        }
        if (event.row !== undefined) {
            if (!event.row.isNewRow) {
                event.editorOptions.disabled = true;
            }
        }
    };

    componentDidMount() {
        fetch(`${config.authUrl}`)
            .then(response => {
                response.json()
                    .then(body => {
                        if (body['authenticated'] === false) {
                            this.setState({ isAuthenticated: false });
                            window.location.reload();
                        } else {
                            this.setState({
                                isAuthenticated: true,
                                readOnly: body.readOnly
                            });
                        }
                    });
            });
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        this.setState({ height: parseInt(window.innerHeight) - config.pxToSubtract });
    }
}

class DetailTemplate extends Component {

    render() {
        return (
            <TabPanel>
                <Item title="Contract Status" render={this.renderContractStatusTab} />
                <Item title="Dealer group Members" render={this.renderDealerMembersTab} />
            </TabPanel>
        );
    }

    renderContractStatusTab = () => {
        return <ContractStatusTab dealerInfoId={this.props.data.key} />;
    };

    renderDealerMembersTab = () => {
        return <DealerMembersTab dealerInfoId={this.props.data.key} />;
    }
}

class ContractStatusTab extends Component {

    constructor(props) {
        super(props);
        this.contractStatusDealerInfoId = props.dealerInfoId;
        this.state = {
            dealerInfoId: props.dealerInfoId,
            readOnly: true
        };
        this.contractStatusDataSource = new DataSource({
            store: AspNetData.createStore({
                key: ['brand', 'status'],
                loadUrl: `${config.apiUrl}/dealer-info/contract-status?id=` + this.contractStatusDealerInfoId,
                insertUrl: `${config.apiUrl}/dealer-info/contract-status?id=` + this.contractStatusDealerInfoId,
                updateUrl: `${config.apiUrl}/dealer-info/contract-status?id=` + this.contractStatusDealerInfoId,
                deleteUrl: `${config.apiUrl}/dealer-info/contract-status?id=` + this.contractStatusDealerInfoId
            })
        });
    }

    render() {
        return (
            <React.Fragment>
                <DataGrid
                    dataSource={this.contractStatusDataSource}
                    showBorders={true}
                    columnAutoWidth={true}
                    onSaving={this.onSaving}
                    rowAlternationEnabled={true}
                    showRowLines={true}
                    allowColumnResizing={true}
                    columnResizingMode={'nextColumn'}
                    allowColumnReordering={true}
                >
                    <Editing
                        mode="batch"
                        allowUpdating={!this.state.readOnly}
                        allowAdding={!this.state.readOnly}
                        allowDeleting={!this.state.readOnly}
                    />
                    <Column dataField="brand" />
                    <Column dataField="status" />
                </DataGrid>
            </React.Fragment>
        );
    }

    componentDidMount() {
        fetch(`${config.authUrl}`)
            .then(response => {
                response.json()
                    .then(body => {
                        if (body['authenticated'] === false) {
                            this.setState({ isAuthenticated: false });
                            window.location.reload();
                        } else {
                            this.setState({
                                isAuthenticated: true,
                                readOnly: body.readOnly
                            });
                        }
                    });
            });
    }
}

class DealerMembersTab extends Component {

    constructor(props) {
        super(props);
        this.contractStatusDealerInfoId = props.dealerInfoId;
        this.state = {
            dealerInfoId: props.dealerInfoId,
            readOnly: true
        };
        this.dealerMembersDataSource = new DataSource({
            store: AspNetData.createStore({
                key: ['dealerNo', 'dealerName', 'dealerInfoId'],
                loadUrl: `${config.apiUrl}/dealer-info/group-members?id=` + this.contractStatusDealerInfoId,
                insertUrl: `${config.apiUrl}/dealer-info/group-members?id=` + this.contractStatusDealerInfoId,
                updateUrl: `${config.apiUrl}/dealer-info/group-members?id=` + this.contractStatusDealerInfoId,
                deleteUrl: `${config.apiUrl}/dealer-info/group-members?id=` + this.contractStatusDealerInfoId
            })
        });
    }

    render() {
        return (
            <React.Fragment>
                <DataGrid
                    dataSource={this.dealerMembersDataSource}
                    showBorders={true}
                    columnAutoWidth={true}
                    keyExpr="dealerNo"
                    rowAlternationEnabled={true}
                    showRowLines={true}
                    allowColumnResizing={true}
                    columnResizingMode={'nextColumn'}
                    allowColumnReordering={true}
                >
                    <Editing
                        mode="batch"
                        allowUpdating={!this.state.readOnly}
                        allowAdding={!this.state.readOnly}
                        allowDeleting={!this.state.readOnly}
                    />
                    <Column dataField="dealerNo" />
                    <Column dataField="dealerName" />
                    <MasterDetail
                        enabled={true}
                        component={DealerMemberContractStatusTemplate}
                    />
                </DataGrid>
            </React.Fragment>
        );
    }

    componentDidMount() {
        fetch(`${config.authUrl}`)
            .then(response => {
                response.json()
                    .then(body => {
                        if (body['authenticated'] === false) {
                            this.setState({ isAuthenticated: false });
                            window.location.reload();
                        } else {
                            this.setState({
                                isAuthenticated: true,
                                readOnly: body.readOnly
                            });
                        }
                    });
            });
    }
}

class DealerMemberContractStatusTemplate extends Component {

    constructor(props) {
        super(props);
        this.groupMemberContractStatusKey = props.data.key;
        this.groupMemberContractStatusDataSource = new DataSource({
            store: AspNetData.createStore({
                key: ['brand', 'status', 'dealerInfoId', 'dealerNo'],
                loadUrl: `${config.apiUrl}/dealer-info/group-members/contract-status?id=` + this.groupMemberContractStatusKey.dealerInfoId + '&dealerNo=' + this.groupMemberContractStatusKey.dealerNo,
                insertUrl: `${config.apiUrl}/dealer-info/group-members/contract-status?id=` + this.groupMemberContractStatusKey.dealerInfoId + '&dealerNo=' + this.groupMemberContractStatusKey.dealerNo,
                updateUrl: `${config.apiUrl}/dealer-info/group-members/contract-status?id=` + this.groupMemberContractStatusKey.dealerInfoId + '&dealerNo=' + this.groupMemberContractStatusKey.dealerNo,
                deleteUrl: `${config.apiUrl}/dealer-info/group-members/contract-status?id=` + this.groupMemberContractStatusKey.dealerInfoId + '&dealerNo=' + this.groupMemberContractStatusKey.dealerNo
            })
        });
        this.state = {
            readOnly: true
        };
    }

    render() {
        return (
            <React.Fragment>
                <DataGrid
                    dataSource={this.groupMemberContractStatusDataSource}
                    showBorders={true}
                    columnAutoWidth={true}
                    rowAlternationEnabled={true}
                    showRowLines={true}
                    allowColumnResizing={true}
                    columnResizingMode={'nextColumn'}
                    allowColumnReordering={true}
                >
                    <Editing
                        mode="batch"
                        allowUpdating={!this.state.readOnly}
                        allowAdding={!this.state.readOnly}
                        allowDeleting={!this.state.readOnly}
                    />
                    <Column dataField="brand" />
                    <Column dataField="status" />
                </DataGrid>
            </React.Fragment>
        );
    }

    componentDidMount() {
        fetch(`${config.authUrl}`)
            .then(response => {
                response.json()
                    .then(body => {
                        if (body['authenticated'] === false) {
                            this.setState({ isAuthenticated: false });
                            window.location.reload();
                        } else {
                            this.setState({
                                isAuthenticated: true,
                                readOnly: body.readOnly
                            });
                        }
                    });
            });
    }
}


export default DealerInfoComponent;