import React, { Component } from 'react';
import Button from 'devextreme-react/button';
import DataGrid, {
    Column, ColumnFixing,
    Editing,
    FilterRow, HeaderFilter,
    Lookup,
    Pager,
    Paging,
    RemoteOperations,
    RequiredRule,
    Scrolling, Sorting
} from "devextreme-react/data-grid";
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import config from "../../common/config/config";
import { CustomRule, PatternRule } from "devextreme-react/validator";

const dataSource = AspNetData.createStore({
    key: 'identityId',
    loadUrl: `${config.apiUrl}/service-history`,
    insertUrl: `${config.apiUrl}/service-history`,
    updateUrl: `${config.apiUrl}/service-history`,
    deleteUrl: `${config.apiUrl}/service-history`
});

const dealersDataSource = {
    store: AspNetData.createStore({
        loadUrl: `${config.apiUrl}/dealers`,
    }),
    paginate: true,
    pageSize: config.pageSize
};

const customersDataSource = {
    store: AspNetData.createStore({
        loadUrl: `${config.apiUrl}/customers/ids`,
    }),
    paginate: true,
    pageSize: config.pageSize
};


class ServiceHistoryComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentPage: 0,
            pageLimit: 10,
            totalRecords: 0,
            pageCount: 0,
            pageNeighbours: 2,
            showFilterRow: true,
            showHeaderFilter: true,
            currentFilter: 'auto',
            selectTextOnEditStart: true,
            startEditAction: 'click',
            errors: [],
            isAuthenticated: false,
            user: undefined,
            readOnly: true,
            height: window.innerHeight - config.pxToSubtract
        };
    }

    startSync = () => {

        const endpoint = 'job/start/transactional/service-history-import'
        const origins = process.env.REACT_APP_API_BASE_URLS.split(',')
        const urls = origins.map(url => url + endpoint)

        urls.forEach((url) => {
            const requestBody = {
                url: url
            };
            fetch(`${config.apiUrl}/rp`, {
                method: 'POST',
                body: JSON.stringify(requestBody),
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(response => response.text())
                .then(data => {
                    console.log(data)
                    if (data.includes('started with ID ')) {
                        alert(`Sync started successfully for ${url}!`);
                    } else {
                        alert(`Data sync has encountered an issue for ${url}!`);
                    }
                }).catch(error => console.error(error));
        });
    }

    render() {
        return (
            <div>
                <h1>Service History</h1>
                <div>
                    <ul style={{ listStyleType: "none" }}>
                        {this.state.errors.map((value, index) => {
                            return <li style={{ color: "red" }} key={index}>{value}</li>
                        })}
                    </ul>
                </div>
                <div className="datagrid">
                    <Button text="Sync" onClick={this.startSync} />

                    <DataGrid id="gridContainer"
                        dataSource={dataSource}
                        showBorders={true}
                        onEditorPreparing={this.onEditorPreparing}
                        rowAlternationEnabled={true}
                        showRowLines={true}
                        allowColumnResizing={true}
                        columnResizingMode={'nextColumn'}
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        height={this.state.height}
                    >
                        <Sorting mode="multiple" />
                        <ColumnFixing enabled="true" />
                        <RemoteOperations paging={true} filtering={true} sorting={true} grouping={true} />
                        <Paging defaultPageSize={config.defaultPageSize} />
                        <Scrolling mode="virtual" rowRenderingMode="virtual" />
                        <Editing
                            mode="batch"
                            allowUpdating={!this.state.readOnly}
                            allowAdding={!this.state.readOnly}
                            allowDeleting={!this.state.readOnly}
                        />
                        <FilterRow visible={this.state.showFilterRow}
                            applyFilter={this.state.currentFilter}
                            showAllText="" />
                        <HeaderFilter visible={this.state.showHeaderFilter} />
                        <Column dataField="groupNo" />
                        <Column dataField="dealerNo">
                            <RequiredRule message="Dealer number is required" />
                            <Lookup
                                dataSource={dealersDataSource}
                            />
                        </Column>
                        <Column dataField="customerId">
                            <RequiredRule message="Customer id is required" />
                            <Lookup
                                dataSource={customersDataSource}
                            />
                        </Column>
                        <Column dataField="firstInvoiceDate" dataType="date" format="yyyy-MM-dd"
                            allowHeaderFiltering={false}>
                            <RequiredRule message="First Invoice Date is required" />
                        </Column>
                        <Column dataField="orderNo">
                            <RequiredRule message="Order number is required" />
                        </Column>
                        <Column dataField="orderTypes">
                            <RequiredRule message="Order types are required" />
                        </Column>
                        <Column dataField="modelText" />
                        <Column dataField="vehicleRegistrationNo" />
                        <Column dataField="vin">
                            <RequiredRule message="Vin is a required field" />
                        </Column>
                        <Column dataField="firstMileage">
                            <CustomRule reevaluate={true} validationCallback={this.integerValidator} message="First Mileage must be >= -2,147,483,648 and <= 2,147,483,647" />
                        </Column>
                        <Column dataField="firstServicePersonName" />
                        <Column dataField="serviceRevenueCustomer">
                            <RequiredRule message="Service Revenue Customer is required" />
                        </Column>
                        <Column dataField="serviceRevenueOthers">
                            <RequiredRule message="Service Revenue Others is required" />
                        </Column>
                        <Column dataField="brand">
                            <RequiredRule message="Brand is required" />
                        </Column>
                        <Column dataField="firstInvoiceDealerNo">
                            <RequiredRule message="First Invoice Dealer No field is required" />
                            <Lookup
                                dataSource={dealersDataSource}
                            />
                        </Column>
                        <Column dataField="lastInvoiceDate" dataType="date" format="yyyy-MM-dd"
                            allowHeaderFiltering={false}>
                            <RequiredRule message="Last Invoice Date field is required" />
                        </Column>
                        <Column dataField="lastInvoiceDealerNo">
                            <RequiredRule message="Last Invoice Dealer No field is required" />
                            <Lookup
                                dataSource={dealersDataSource}
                            />
                        </Column>
                        <Column dataField="isOwner" dataType="boolean">
                            <CustomRule reevaluate={true} validationCallback={this.validationCallback} message="Is Owner field is required" />
                        </Column>
                        <Column dataField="isRelated" dataType="boolean">
                            <CustomRule reevaluate={true} validationCallback={this.validationCallback} message="Is Related field is required" />
                        </Column>
                        <Column dataField="modelKey" />
                        <Column dataField="generalInspectionDate">
                            <PatternRule
                                message={'General Inspection Date must have "yyyy-MM" format!'}
                                pattern={/^\d{4}-\d{2}$/i}
                            />
                        </Column>
                        <Column dataField="qmGeneralInspectionDateValid" dataType="boolean">
                            <CustomRule reevaluate={true} validationCallback={this.validationCallback} message="Qm General Inspection Date Valid field is required" />
                        </Column>
                        <Column dataField="registrationDate" dataType="date" format="yyyy-MM-dd"
                            allowHeaderFiltering={false} />
                        <Column dataField="mileageAnnual" />
                        <Column dataField="isDriver" dataType="boolean">
                            <CustomRule reevaluate={true} validationCallback={this.validationCallback} message="Is Driver field is required" />
                        </Column>
                        <Column dataField="isInvoiceRecipient" dataType="boolean">
                            <CustomRule reevaluate={true} validationCallback={this.validationCallback} message="Is Invoice Recipient field is required" />
                        </Column>
                    </DataGrid>
                </div>
            </div>
        );
    };

    validationCallback = (data) => {
        return data.value !== undefined && data.value !== null;
    };

    integerValidator = (data) => {
        return data.value >= config.integerMinValue && data.value <= config.integerMaxValue;
    };

    onEditorPreparing = (event) => {
        if (event.dataField !== "id" && event.dataField !== "groupNo") {
            return;
        }
        if (event.row !== undefined) {
            event.editorOptions.disabled = true;
        }
    };

    componentDidMount() {
        fetch(`${config.authUrl}`)
            .then(response => {
                response.json()
                    .then(body => {
                        if (body['authenticated'] === false) {
                            this.setState({ isAuthenticated: false });
                            window.location.reload();
                        } else {
                            this.setState({
                                isAuthenticated: true,
                                readOnly: body.readOnly
                            });
                        }
                    });
            });
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }


    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        this.setState({ height: parseInt(window.innerHeight) - config.pxToSubtract });
    }
}


export default ServiceHistoryComponent;
